interface wipES {
  wip_title: string;
  wip_desc_1: string;
  wip_desc_2: string;
}

export const wipES: wipES = {
  wip_title: "Trabajo en progreso",
  wip_desc_1: "Esta página aún está en construcción",
  wip_desc_2: "Por favor, regresa más tarde",
};

interface missingES {
  missing_title: string;
  missing_desc_1: string;
  home_page: string;
}

export const missingES: missingES = {
  missing_title: "¡Vaya! Página no encontrada",
  missing_desc_1: "Parece que la página a la que intentas acceder no existe.",
  home_page: "Ir a la página de inicio",
};

interface errorPageES {
  error_title: string;
  error_desc_1: string;
  error_desc_2: string;
}

export const errorPageES: errorPageES = {
  error_title: "¡Vaya! Ha ocurrido un error inesperado",
  error_desc_1: "Se ha detectado el siguiente error:",
  error_desc_2:
    "Si el error sigue apareciendo, por favor contáctanos y avísanos.",
};
