"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import imgError from "@/../public/images/abstract/Error.png";
import { errorPageEN } from "@/assets/dictionaries/en/wip-content";
import { errorPageES } from "@/assets/dictionaries/es/wip-content";

export default function ErrorBoundary({ error }: { error: Error }) {
  const [errorContent, setErrorContent] = useState(errorPageEN); // Default to English

  useEffect(() => {
    // Get the language from localStorage
    const lang = localStorage.getItem("selectedOption") || "EN"; // Default to EN if not found

    // Set the content based on the language
    switch (lang.toUpperCase()) {
      case "ES":
        setErrorContent(errorPageES);
        break;
      case "EN":
      default:
        setErrorContent(errorPageEN);
        break;
    }
  }, []); // Only run on mount
  return (
    <div>
      <section className="px-page-xl pt-16">
        <div className="py-16 flex flex-col gap-16 md:flex-row">
          <div className="flex flex-col w-full md:w-2/4 justify-center">
            <div className="text-4xl text-gbci-primary font-bold">
              {errorContent.error_title}
            </div>
            <div className="text-3xl font-bold py-8">
              {errorContent.error_desc_1}
            </div>
            <div className="text-xl font-bold py-8">{error.message}</div>
            <div className="text-3xl text-gbci-accent font-bold py-8">
              {errorContent.error_desc_2}
            </div>
          </div>
          <div className="flex flex-col w-full md:w-2/4">
            <Image className="w-full" src={imgError} alt="404 img" />
          </div>
        </div>
      </section>
    </div>
  );
}
