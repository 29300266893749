interface wipEN {
  wip_title: string;
  wip_desc_1: string;
  wip_desc_2: string;
}

export const wipEN: wipEN = {
  wip_title: "Work In Progress",
  wip_desc_1: "This page is still under construction",
  wip_desc_2: "Please check back later",
};

interface missingEN {
  missing_title: string;
  missing_desc_1: string;
  home_page: string;
}

export const missingEN: missingEN = {
  missing_title: "Oops! Page not found",
  missing_desc_1:
    "It looks like the page you are trying to access does not exist.",
  home_page: "Go to homepage",
};

interface errorPageEN {
  error_title: string;
  error_desc_1: string;
  error_desc_2: string;
}

export const errorPageEN: errorPageEN = {
  error_title: "Oops! An unexpected error has ocurred",
  error_desc_1: "The following error has been detected:",
  error_desc_2:
    "If the error keeps appearing please contact us and let us know!",
};
